import React from 'react';
import PropType from 'prop-types';
import PageIconAave from './protocols/page-aave.svg';
import PageIconCompound from './protocols/page-compound.svg';
import PageIconExchange from './protocols/page-exchange.svg';
import PageIconMaker from './protocols/page-maker.svg';
import PageIconLiquity from './protocols/page-liquity.svg';
import PageIconReflexer from './protocols/page-reflexer.svg';
import PageIconLido from './protocols/page-lido.svg';
import PageIconUniswap from './protocols/page-uniswap.svg';
import PageIconYearn from './protocols/page-yearn.svg';
import PageIconRecipe from './protocols/page-recipe.svg';
import PageIconMstable from './protocols/page-mstable.svg';
import PageIconChickenBonds from './protocols/chicken-bonds.svg';
import PageIconBalancer from './protocols/balancer.svg';
import PageIconMorpho from './protocols/morpho.svg';
import automation from './protocols/automation.svg';
import notAutomated from './protocols/automation-off.svg';
import boost from './actions/boost.svg';
import repay from './actions/repay.svg';
import shift from './actions/shift.svg';
import savings from './actions/savings.svg';
import bridge from './actions/bridge.svg';
import close from './actions/close.svg';
import create from './actions/create.svg';
import importIcon from './actions/import.svg';
import withdrawIcon from './actions/withdraw.svg';
import addIcon from './actions/add.svg';
import supplyIcon from './actions/supply.svg';
import migrateIcon from './actions/migrate.svg';
import longIcon from './actions/long.svg';
import shortIcon from './actions/short.svg';
import levstakingIcon from './actions/levstaking.svg';
import claimIcon from './actions/claim.svg';
import moveIcon from './actions/move.svg';
import arbitrum from './networks/arbitrum.svg';
import optimism from './networks/optimism.svg';
import ethereum from './networks/ethereum-mainnet.svg';
import base from './networks/base.svg';
import placeholder from './protocols/placeholder.svg';

import aaveV2 from './protocols/aave-v2.svg';
import aaveV3 from './protocols/aave-v3.svg';
import compV2 from './protocols/compound-v2.svg';
import compV3 from './protocols/compound-v3.svg';
import morphoAaveV2 from './protocols/morpho-aave-v2.svg';
import morphoAaveV3 from './protocols/morpho-aave-v3.svg';
import crvUSD from './protocols/crvUSD.svg';
import llamaLend from './protocols/page-llamalend.svg';
import spark from './protocols/spark.svg';
import ethsaver from './protocols/ethsaver.svg';
import morphoBlue from './protocols/morpho-blue.svg';
import txsaver from './protocols/txsaver.svg';
import liquityV2 from './protocols/page-liquity-v2.svg';
import fluid from './protocols/page-fluid.svg';

import dca from './actions/dca.svg';
import limit_order from './actions/limit-order.svg';
import self_liquidate from './actions/self-liquidate.svg';

const getProtocolIcon = (protocol: string):(string | void) => {
  if (!protocol) return;
  if (protocol.toLowerCase() === 'automation') return automation;
  if (protocol.toLowerCase() === 'automated') return automation;
  if (protocol.toLowerCase() === 'not_automated') return notAutomated;
  if (protocol.toLowerCase().includes('maker')) return PageIconMaker;
  if (['morpho', 'blue'].every(term => protocol.toLowerCase().includes(term))) return morphoBlue;
  if (['morpho', 'aave', 'v2'].every(term => protocol.toLowerCase().includes(term))) return morphoAaveV2;
  if (['morpho', 'aave', 'v3'].every(term => protocol.toLowerCase().includes(term))) return morphoAaveV3;
  if (['compound', 'v3'].every(term => protocol.toLowerCase().includes(term))) return compV3;
  if (protocol.toLowerCase().includes('compound')) return compV2;
  if (['aave', 'v2'].every(term => protocol.toLowerCase().includes(term))) return aaveV2;
  if (['aave', 'v3'].every(term => protocol.toLowerCase().includes(term))) return aaveV3;
  if (protocol.toLowerCase().includes('exchange')) return PageIconExchange;
  if (protocol.toLowerCase().includes('reflexer')) return PageIconReflexer;
  if (['liquity', 'v2'].every(term => protocol.toLowerCase().includes(term))) return liquityV2;
  if (protocol.toLowerCase().includes('fluid')) return fluid;
  if (protocol.toLowerCase().includes('liquity')) return PageIconLiquity;
  if (protocol.toLowerCase().includes('lido')) return PageIconLido;
  if (protocol.toLowerCase().includes('uniswap')) return PageIconUniswap;
  if (protocol.toLowerCase().includes('yearn')) return PageIconYearn;
  if (protocol.toLowerCase().includes('recipe')) return PageIconRecipe;
  if (protocol.toLowerCase().includes('mstable')) return PageIconMstable;
  if (protocol.toLowerCase().includes('bonds')) return PageIconChickenBonds;
  if (protocol.toLowerCase().includes('balancer')) return PageIconBalancer;
  if (protocol.toLowerCase().includes('crv_usd') || protocol.toLowerCase().includes('curveusd')) return crvUSD;
  if (protocol.toLowerCase().includes('llama_lend') || protocol.toLowerCase().includes('llamalend')) return llamaLend;
  if (protocol.toLowerCase().includes('spark')) return spark;
  if (protocol.toLowerCase().includes('eth saver') || protocol.toLowerCase().includes('ethsaver')) return ethsaver;
  if (protocol.toLowerCase().includes('tx saver') || protocol.toLowerCase().includes('ethsaver')) return txsaver;
};

const getActionIcon = (action: string):(string | void) => {
  if (!action) return;
  if (action.toLowerCase().includes('boost')) return boost;
  if (action.toLowerCase().includes('repay')) return repay;
  if (action.toLowerCase().includes('shift')) return shift;
  if (action.toLowerCase().includes('savings')) return savings;
  if (action.toLowerCase().includes('bridge')) return bridge;
  if (action.toLowerCase().includes('create')) return create;
  if (action.toLowerCase().includes('close')) return close;
  if (action.toLowerCase().includes('import')) return importIcon;
  if (action.toLowerCase().includes('withdraw')) return withdrawIcon;
  if (action.toLowerCase().includes('migrate')) return migrateIcon;
  if (action.toLowerCase().includes('long')) return longIcon;
  if (action.toLowerCase().includes('short')) return shortIcon;
  if (action.toLowerCase().includes('leveraged staking')) return levstakingIcon;
  if (action.toLowerCase().includes('claim')) return claimIcon;
  if (action.toLowerCase().includes('move')) return moveIcon;
  if (action.toLowerCase().includes('supply')) return supplyIcon;
  if (action.toLowerCase().includes('dca')) return dca;
  if (action.toLowerCase().includes('limit_order')) return limit_order;
  if (action.toLowerCase().includes('self-liquidate')) return self_liquidate;
  if (action.toLowerCase().includes('additional')) return addIcon;
};

const getNetworkIcon = (network: string):(string | void) => {
  if (!network) return;
  if (network.toLowerCase().includes('ethereum')) return ethereum;
  if (network.toLowerCase().includes('mainnet')) return ethereum;
  if (network.toLowerCase().includes('optimism')) return optimism;
  if (network.toLowerCase().includes('arbitrum')) return arbitrum;
  if (network.toLowerCase().includes('base')) return base;
};

export const getTagIcon = (tag: string): string => getProtocolIcon(tag)
    || getActionIcon(tag)
    || getNetworkIcon(tag)
    || placeholder;

const TagIcon = ({ tag, size }: { tag: string, size: number }) => {
  const url = getTagIcon(tag === 'redemption_protection' ? 'repay' : tag);
  return (
    <img src={url} alt={tag} height={size} width={size} />
  );
};

TagIcon.propTypes = {
  size: PropType.number,
};

TagIcon.defaultProps = {
  size: 20,
};

export default TagIcon;
