import React, { useCallback, useEffect, useMemo } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import { DfsMultiselect, DfsSelect } from '@defisaver/components';
import '@defisaver/components/dist/bundle.css';
import { ValueContainerProps, components } from 'react-select';
import { useGetFeedDataQuery } from './feedApi';
import {
  formatAcc,
  formatEtherScanLink,
  parseTimeDelta,
  parseTimestamp,
} from '../../services/utils';
import ExternalLink from '../decorative/ExternalLink';
import './Feed.scss';
import {
  formatAction,
  getTags,
} from './formattingUtils';
import TagIcon, { getTagIcon } from '../decorative/TagIcon/TagIcon';
import { useFilter } from './filterHooks';
import 'semantic-ui-css/components/dropdown.css';
import PositionLink from '../decorative/PositionLink';
import TooltipWrapper from '../decorative/TooltipWrapper/TooltipWrapper';
import AllIcon from '../decorative/TagIcon/AllIcon';

const leverageTypeOptions = [
  { value: 'all', label: 'All', icon: <AllIcon /> },
  { value: 'staking', label: 'Staking', icon: <img src={getTagIcon('leveraged staking')} alt="network" /> },
  { value: 'long', label: 'Long', icon: <img src={getTagIcon('long')} alt="network" /> },
  { value: 'short', label: 'Short', icon: <img src={getTagIcon('short')} alt="network" /> },
];

const actionOptions = [
  { value: 'boost', label: 'Boost' },
  { value: 'repay', label: 'Repay' },
  { value: 'create', label: 'Create' },
  { value: 'close', label: 'Close' },
  { value: 'import', label: 'Import' },
  { value: 'migrate', label: 'Migrate' },
  { value: 'recipe_creator', label: 'Recipe' },
  { value: 'loan_shift', label: 'Loan Shift' },
  { value: 'withdraw', label: 'Withdraw' },
  { value: 'supply', label: 'Supply' },
  { value: 'move', label: 'Move' },
  { value: 'claim', label: 'Claim' },
  { value: 'dca', label: 'DCA' },
  { value: 'limit_order', label: 'Limit Order' },
  { value: 'chicken_in', label: 'Chicken In' },
  { value: 'chicken_out', label: 'Chicken Out' },
  { value: 'claim_sell', label: 'Claim & Sell' },
  { value: 'self-liquidate', label: 'Self-Liquidate' },
].map((option) => ({ ...option, icon: <img src={getTagIcon(option.value)} alt="network" /> }));
const networkOptions = [
  { value: 'ethereum', label: 'Ethereum' },
  { value: 'optimism', label: 'Optimism' },
  { value: 'arbitrum', label: 'Arbitrum' },
  { value: 'base', label: 'Base' },
].map((option) => ({ ...option, icon: <img src={getTagIcon(option.value)} alt="network" /> }));
const protocolOptions = [
  { value: 'aave_v2', label: 'Aave v2' },
  { value: 'aave_v3', label: 'Aave v3' },
  { value: 'compound', label: 'Compound v2' },
  { value: 'compound_v3', label: 'Compound v3' },
  { value: 'liquity', label: 'Liquity' },
  { value: 'liquity_v2', label: 'Liquity V2' },
  { value: 'fluid', label: 'Fluid' },
  { value: 'maker', label: 'Maker' },
  { value: 'morpho_aave_v2', label: 'Morpho Aave V2' },
  { value: 'morpho_aave_v3', label: 'Morpho Aave V3' },
  { value: 'morpho_blue', label: 'Morpho Blue' },
  { value: 'reflexer', label: 'Reflexer' },
  { value: 'savings', label: 'Savings' },
  { value: 'exchange', label: 'Exchange' },
  { value: 'chicken_bonds', label: 'Chicken Bonds' },
  { value: 'crv_usd', label: 'CurveUSD' },
  { value: 'llama_lend', label: 'LlamaLend' },
  { value: 'spark', label: 'Spark' },
  { value: 'ethsaver', label: 'ETH Saver' },
  // { value: 'shifter', label: 'Shifter' },
].map((option) => ({ ...option, icon: <img src={getTagIcon(option.value)} alt="network" /> }));

const Feed = (props: any) => {
  const [params, setParam] = useFilter();
  const defaultParams = useMemo(() => ({
    leverageType: leverageTypeOptions.find((option) => option.value === params.leverageType) || { value: 'all', label: 'All', icon: <AllIcon /> },
    actions: actionOptions.filter((option) => params.actions?.includes(option.value)),
    networks: networkOptions.filter((option) => params.networks?.includes(option.value)),
    protocol: protocolOptions.filter((option) => params.protocols?.includes(option.value)),
  }), []);
  const { data, error, isFetching } = useGetFeedDataQuery(params);

  const SelectValueContainer = useCallback((props: ValueContainerProps) => {
    const options = props.getValue();

    return (
      <components.ValueContainer {...props}>
        <div className="default-select-value-wrapper">
          {Object.keys(options[0] as Object).length > 0 ? (
            <div className="icon-wrapper">
              {(options[0] as any).icon}
            </div>
          ) : (
            <>
              {(props.children as React.ReactNode[])[0]}
            </>
          )}
          {(props.children as React.ReactNode[])[0]}
          {/* MUST return this element!!!!! */}
          {(props.children as React.ReactNode[])[1]}
        </div>
      </components.ValueContainer>
    );
  }, []);

  return (
    <div className="feed-wrapper">
      {/* @ts-ignore */}
      <Helmet>
        <title>Activity Feed | DeFi Saver</title>
      </Helmet>
      <div className="width-container">
        <h1>Activity Feed</h1>
        {error && JSON.stringify(error)}

        <div className="filters-wrapper">
          {isFetching && <div className="loader-wrapper">Loading...</div>}
          <div className="filter-wrapper">
            <div className="leverage-label">Leverage:</div>
            <DfsSelect
              options={leverageTypeOptions}
              value={defaultParams.leverageType as any}
              onValueChange={(data: any) => {
                setParam('leverageType', data.value?.toString() || '');
              }}
              style={{ minWidth: '125px', maxWidth: '125px', backgroundColor: 'transparent' }}
              selectValueContainer={SelectValueContainer}
            />
          </div>
          <div className="filter-wrapper">
            <div className="leverage-label">Network:</div>
            <DfsMultiselect
              options={networkOptions}
              loading={false}
              onValueChange={(data) => {
                setParam('networks', data.map(d => d.value).join(','));
              }}
              value={defaultParams.networks}
              style={{ minWidth: '125px', maxWidth: '125px', backgroundColor: 'transparent' }}
              bordered
              tooltip
            />
          </div>
          <div className="filter-wrapper">
            <div className="leverage-label">Protocol:</div>
            <DfsMultiselect
              options={protocolOptions}
              loading={false}
              onValueChange={(data) => {
                setParam('protocols', data.map(d => d.value).join(','));
              }}
              value={defaultParams.protocol}
              selectedCutOff={3}
              style={{ minWidth: '125px', maxWidth: '125px', backgroundColor: 'transparent' }}
              bordered
              tooltip
            />
          </div>
          <div className="filter-wrapper">
            <div className="leverage-label">Action:</div>
            <DfsMultiselect
              options={actionOptions}
              loading={false}
              onValueChange={(data) => {
                setParam('actions', data.map(d => d.value).join(','));
              }}
              value={defaultParams.actions}
              selectedCutOff={3}
              style={{ minWidth: '125px', maxWidth: '125px', backgroundColor: 'transparent' }}
              bordered
              tooltip
            />
          </div>
        </div>

        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Time</th>
                <th>Event</th>
                <th>Position</th>
                <th>{/* automated */}</th>
                <th>{/* icon */}</th>
                <th>Tags</th>
                <th>{/* explorer */}</th>
              </tr>
            </thead>
            <tbody>
              {data && data.map((row) => (
                <>
                  <tr key={`${row.txHash}-${row.action}-${row.address}`} className={`${row.additionalEvent ? 'has-additional' : ''}`}>
                    <td title={parseTimestamp(row.timestamp)}>
                      <span className="timestamp">
                        {parseTimeDelta(row.timestamp)}
                      </span>
                    </td>
                    <td>
                      <div className="title">{row.title}</div>
                      {row.description && (
                      <div className="subtitle">
                        {row.description}
                      </div>
                      )}
                    </td>
                    <td>
                      <span className="mobile-only">Position: </span>
                      {/* <ExternalLink href={formatEtherScanLink(row.address, 'address', row.network)}>{formatAcc(row.address)}</ExternalLink> */}
                      <PositionLink positionId={row.cdpId || row.bondId || row.address} protocol={row.protocol} network={row.network}>
                        {row.cdpId
                          ? `CDP #${row.cdpId}`
                          : row.bondId
                            ? `Bond #${row.bondId}`
                            : `${formatAcc(row.address)}`}
                      </PositionLink>
                    </td>
                    <td className="desktop-only">
                      <TooltipWrapper title={row.automated ? 'Automated' : 'Manual'}>
                        <TagIcon
                          tag={row.automated ? 'automated' : 'not_automated'}
                          size={24}
                        />
                      </TooltipWrapper>
                      { row.isTxSaver && (
                      <TooltipWrapper title="Executed by Tx Saver">
                        <TagIcon
                          tag="tx saver"
                          size={24}
                        />
                      </TooltipWrapper>
                      ) }
                    </td>
                    <td className="desktop-only">
                      <span className="tag">
                        <TagIcon tag={row.action} size={24} />
                        {formatAction(row)}
                      </span>
                    </td>
                    <td className="desktop-only">
                      <div className="tags">
                        {getTags(row).map((tag) => (
                          <span className="tag"><TagIcon tag={tag} size={24} />{tag}</span>
                        ))}
                      </div>
                    </td>
                    <td className="mobile-only">
                      <div className="tags">
                        <TagIcon
                          tag={row.automated ? 'automated' : 'not_automated'}
                          size={24}
                        />
                        { row.isTxSaver && (
                        <TooltipWrapper title="Executed by Tx Saver">
                          <TagIcon
                            tag="tx saver"
                            size={24}
                          />
                        </TooltipWrapper>
                        ) }
                        <span className="tag">
                          <TagIcon tag={row.action} size={24} />
                          {formatAction(row)}
                        </span>
                        {getTags(row).map((tag) => (
                          <span className="tag"><TagIcon tag={tag} size={24} />{tag}</span>
                        ))}
                      </div>
                    </td>
                    <td>
                      <ExternalLink
                        href={formatEtherScanLink(
                          row.txHash,
                          'tx',
                          row.network,
                        )}
                        showIcon
                        className="explorer-link"
                      />
                    </td>
                  </tr>
                  { row.additionalEvent && (
                  <tr key={`${row.txHash}-${row.additionalEvent.action}-${row.address}-additional`}>
                    <td />
                    <td>
                      <div className="title">{row.additionalEvent.title}</div>
                      {row.additionalEvent.description && (
                      <div className="subtitle">
                        {row.additionalEvent.description}
                      </div>
                      )}
                    </td>
                    <td>
                      <span className="mobile-only">Position: </span>
                      <PositionLink
                        positionId={row.additionalEvent.cdpId || row.additionalEvent.bondId || row.additionalEvent.address}
                        protocol={row.additionalEvent.protocol}
                        network={row.additionalEvent.network}
                      >
                        {row.additionalEvent.cdpId
                          ? `CDP #${row.additionalEvent.cdpId}`
                          : row.additionalEvent.bondId
                            ? `Bond #${row.additionalEvent.bondId}`
                            : `${formatAcc(row.additionalEvent.address)}`}
                      </PositionLink>
                    </td>
                    <td className="desktop-only">
                      <TooltipWrapper title={row.additionalEvent.automated ? 'Automated' : 'Manual'}>
                        <TagIcon
                          tag={row.additionalEvent.automated ? 'automated' : 'not_automated'}
                          size={24}
                        />
                        { row.additionalEvent.isTxSaver && (
                        <TooltipWrapper title="Executed by Tx Saver">
                          <TagIcon
                            tag="tx saver"
                            size={24}
                          />
                        </TooltipWrapper>
                        ) }
                      </TooltipWrapper>
                    </td>
                    <td className="desktop-only">
                      <span className="tag">
                        <TagIcon tag={row.additionalEvent.action} size={24} />
                        {formatAction(row.additionalEvent)}
                      </span>
                    </td>
                    <td className="desktop-only">
                      <div className="tags">
                        {getTags(row.additionalEvent, true).map((tag) => (
                          <span className="tag"><TagIcon tag={tag} size={24} />{tag}</span>
                        ))}
                      </div>
                    </td>
                    <td className="mobile-only">
                      <div className="tags">
                        <TagIcon
                          tag={row.additionalEvent.automated ? 'automated' : 'not_automated'}
                          size={24}
                        />
                        { row.additionalEvent.isTxSaver && (
                        <TooltipWrapper title="Executed by Tx Saver">
                          <TagIcon
                            tag="tx saver"
                            size={24}
                          />
                        </TooltipWrapper>
                        ) }
                        <span className="tag">
                          <TagIcon tag={row.additionalEvent.action} size={24} />
                          {formatAction(row)}
                        </span>
                        {getTags(row.additionalEvent, true).map((tag) => (
                          <span className="tag"><TagIcon tag={tag} size={24} />{tag}</span>
                        ))}
                      </div>
                    </td>
                  </tr>
                  )}
                </>
              ))}
            </tbody>
          </table>
        </div>
        {data && <div className="label-after-data">Showing up to 100 latest filtered events</div>}
      </div>
    </div>
  );
};

export default Feed;
